class WebSocketInterface {
    constructor(url, params) {
        this._url = url;
        this._params = params;
        this._socket = null;
        this._listeners = {};
        this._connecting = false;
        this._reconnecting = false;
        this._connAttempts = 0;

        this.connect();
    }

    get url() {
        return this._url;
    }

    get params() {
        return this._params;
    }

    get socket() {
        return this._socket;
    }

    get listeners() {
        return this._listeners;
    }

    get connecting() {
        return this._connecting;
    }

    get reconnecting() {
        return this._reconnecting;
    }

    instance() {
        return this._socket;
    }

    prepareQuery() {
        let query = '';

        if (this._params && this._params.hasOwnProperty('query')) {
            Object.keys(this._params.query).forEach((key) => {
                let param = this._params.query[key];
                query += (query.length === 0 ) ? '?' : '&';
                query += `${key}=${param}`;
            });
        }

        return query;
    }

    connect() {
        this._socket = new WebSocket(this._url + this.prepareQuery());
        this._connecting = true;
        this._reconnecting = false;

        this._socket.onmessage = (msg) => {
            let data = JSON.parse(msg.data);

            if (this._listeners.hasOwnProperty(data.event)) {
                this._listeners[data.event](msg);
            }
        };

        this._socket.onerror = (evt) => {
            if (evt.code !== 1000) {
                this.reconnect();
            }
        };
    }

    reconnect() {
        if (this._reconnecting) {
            return false;
        }

        this._reconnecting = true;

        if (this._socket) {
            this.disconnect();
        }

        this._connAttempts += 1;

        const timeout = this._connAttempts * 1000;
        return setTimeout(() => {
            return this.connect();
        }, timeout);
    }

    disconnect() {
        if (!this._connecting) {
            return false;
        }

        this._connecting = false;
        this._socket.close(1000, 'Соединение закрыто');
    }

    on(evt, callback) {
        this._listeners[evt] = callback;
    }
}

export default WebSocketInterface;