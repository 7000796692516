class Actions {
    constructor() {
        this.initEvents();
        this.initSubscriber();
    }

    initSubscriber() {
        let topics = {};
        jQuery.Topic = function (id) {
            let callbacks, topic = id && topics[id];

            if (!topic) {
                callbacks = jQuery.Callbacks();
                topic = {
                    publish: callbacks.fire,
                    subscribe: callbacks.add,
                    unsubscribe: callbacks.remove
                };
                if (id) {
                    topics[id] = topic;
                }
            }
            return topic;
        };

        $.Topic('actions::execute::response').subscribe((params) => {
            this.executeResponseAction(params);
        });
    }

    initEvents() {
        const self = this;

        /**
         * Обработка модального окна модерации
         */
        $('body').on("click submit", ".js-execute-action", function (e) {
            const $this = $(e.target).closest('.js-execute-action');
            let postData = {};

            Object.keys($this.data()).filter(function (d) {
                return d.match(new RegExp('post-*'))
            }).forEach((element) => {
                const dataKey = element.substr(4).toLowerCase(),
                    dataValue = String($this.data('post-' + dataKey)).split('::');
                if (dataValue[0] === 'localStorage') {
                    let localStorageValue = window.localStorage.getItem(dataValue[1]);
                    if (dataValue.length > 2) {
                        if (dataValue[2] === 'json_array') {
                            localStorageValue = JSON.parse(localStorageValue);
                            localStorageValue = localStorageValue[dataValue[3]];
                            if (dataValue.length > 3) {
                                if (dataValue[4] === 'join_comma') {
                                    localStorageValue = localStorageValue.join(',');
                                }
                            }
                        }
                    }
                    postData[dataKey] = localStorageValue;
                } else {
                    postData[dataKey] = dataValue[0];
                }
            });

            if ($this.data("action") !== undefined) {
                self.executeResponseAction({
                        'action': $this.data("action"),
                        'url': $this.data("url"),
                        'replace_with': $this.data("replace-with"),
                    },
                    postData,
                    e
                );
            } else {
                if ($this.is("form")) {
                    if (e.type !== 'submit') {
                        return;
                    }

                    const form = $this[0];
                    postData = $this.serialize();

                    if (!form.checkValidity()) {
                        e.preventDefault();
                        return;
                    }
                }

                $.Topic("actions::fetch::start").publish({
                    target: e.target,
                });

                (() => {
                    if ($this.data('method') !== undefined && $this.data('method').length !== 0 && $this.data('method') === 'get') {
                        return $.get($this.data("action-url"), postData);
                    } else {
                        return $.post($this.data("action-url"), postData);
                    }
                })().then((response) => self.executeResponseAction(response))
                    .fail(() => toastr.error("При загрузке данных произошла ошибка!"))
                    .always(() => $.Topic("actions::fetch::end").publish({
                        target: e.target,
                    }));
            }

            e.preventDefault();
        });
    }

    executeResponseAction(response, post_data, event) {
        if (!response.hasOwnProperty('action')) {
            throw new Error('Не передан параметр action');
        }

        const $modalPlacement = $(".js-modal-placement");

        switch (response.action) {
            case 'showModal':
                $(".modal").modal("hide");
                if (event !== undefined) {
                    $.Topic("actions::fetch::start").publish({
                        target: event.target,
                    });
                }
                $.ajax({
                    'url': response.url,
                    'method': post_data === undefined ? 'get' : 'post',
                    'data': post_data === undefined ? {} : post_data,
                }).then((data) => {
                    $modalPlacement.html($(data));
                    $modalPlacement.find(".modal").modal("show", response.parameters);
                    $modalPlacement.find(".modal").modal("show");

                    if ($modalPlacement.find(".form__input_select").length > 0) {
                        const $formGroupWidth = $modalPlacement.find(".form-group").width();

                        $modalPlacement.find(".form__input_select").selectpicker({
                            width: '100%',
                            noneResultsText: "Ничего не найдено!"
                        }).on("loaded.bs.select, show.bs.select", () => {
                            $(".dropdown-menu").first().width($formGroupWidth - 3);
                        });
                    }
                })
                    .fail(() => toastr.error("При загрузке данных произошла ошибка!"))
                    .always(() => {
                        if (event !== undefined) {
                            $.Topic("actions::fetch::end").publish({
                                target: event.target,
                            });
                        }
                    });
                break;
            case 'replace':
                $(response.selector).replaceWith(response.replace_with);
                //TODO: Переделать на коллекцию
                if (response.dont_hide_modal === undefined) {
                    $modalPlacement.find(".modal").modal("hide");
                }
                break;
            case 'hideModal':
                $modalPlacement.find(".modal").modal("hide");
                break;
            case 'publishTopic':
                $.Topic(response.name).publish(response.parameters);
                break;
            case 'redirect':
                document.location = response.url;
                break;
            case 'showToaster':
                switch (response.level) {
                    case 'error':
                        toastr.error(response.message);
                        break;
                    case 'warning':
                        toastr.warning(response.message);
                        break;
                    case 'success':
                        toastr.success(response.message);
                        break;
                    default:
                        throw new Error('Передан некорректный тип сообщения: ' + response.level);
                }
                break;
            case 'collection':
                if (typeof response.parameters !== 'object') {
                    throw new Error('Необходимо передать массив');
                }

                response.parameters.forEach((parameter) => {
                    this.executeResponseAction(parameter);
                });
                break;
            default:
                throw new Error('Неизвестный тип события: ' + response.action);
        }
    }
}

export default Actions;