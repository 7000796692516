import SimpleBar from "simplebar";
import WebSocketInterface from "../../abstracts/js/WebSocketInterface/WebSocketInterface";

class Chat {
    constructor(preventHideOnClick = false) {
        this.$chat = $('.js-chat-modal');
        this._searchVolunteerXhr = null;
        this.$chatToggleButton = $('.js-chat-toggle');
        this.$chatWrapper = $('.chat-widget');
        this.isChatOpen = false;
        this.postIntervals = {};

        if (this.$chatToggleButton.length) {
            this.initEvents(preventHideOnClick);
            this.initScrollbar();
            this.initWebSocket();
            this.updateUnreadStatus();
        }
    }

    initEvents(preventHideOnClick) {
        const $body = $("body"),
            self = this;

        this.$chat.parent().on('show.bs.dropdown', function () {
            self.loadChannels();
        });

        this.$chat.parent().on('shown.bs.dropdown', function () {
            self.$chatToggleButton.addClass("opened");
            self.$chatToggleButton.removeClass("closed");
            self.$chatWrapper.addClass('opened');

            self.isChatOpen = true;
            self.$chatToggleButton.removeClass("unread");
        });

        if (preventHideOnClick) {
            this.$chat.parent().on('hide.bs.dropdown', function (e) {
                if (e.clickEvent) {
                    e.preventDefault();
                }
            });
        }

        this.$chat.parent().on('hidden.bs.dropdown', function () {
            self.$chatToggleButton.removeClass("opened");
            self.$chatToggleButton.addClass("closed");
            self.$chatWrapper.removeClass('opened');

            self.isChatOpen = false;
        });

        let startSearchTimeout;
        $('.js-volunteer-search').on('keyup paste', function () {
            clearTimeout(startSearchTimeout);
            startSearchTimeout = setTimeout(self.searchVolunteer.bind(self, $(this).val()), 500);
        });

        $('.js-chat-tab-search').on('click', self.activateTab.bind(self, '.tab-search'));
        $('.js-chat-tab-channels').on('click', self.activateTab.bind(self, '.tab-channels'));

        // Обработчик для всех кнопок "Написать"
        $body.on("click", '.js-start-chat', function (e) {
            self.startChatClickHandler(this, e);
        });

        $body.on('click', '.js-channel-subscription', function (e) {
            const $spinner = $(this).find('.js-channel-subscription-spinner');

            $spinner.removeClass('d-none');
            $.get($(this).attr('href'))
                .then(() => {
                    self.loadChannels();
                })
                .catch(() => {
                    toastr.error('Произошла ошибка. Попробуйте ещё раз!');
                })
                .always(() => $spinner.addClass('d-none'));

            e.stopPropagation();
            e.preventDefault();
        });
    }

    activateTab(tabClass, e) {
        this.$chat.children('.active').removeClass('active');
        this.$chat.children(tabClass).addClass('active');

        // Если переходим на список каналов - активируем его фоновую перезагрузку
        if (tabClass === '.tab-channels') {
            this.loadChannels();
        }

        if (e instanceof jQuery.Event) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    showSearchSpinner() {
        $('.js-volunteer-search-spinners').removeClass('d-none');
    }

    hideSearchSpinner() {
        $('.js-volunteer-search-spinners').addClass('d-none');
    }

    showChannelSpinner() {
        $('.js-channel-spinners').removeClass('d-none');
    }

    hideChannelSpinner() {
        $('.js-channel-spinners').addClass('d-none');
    }

    searchVolunteer(term) {
        term = term.trim();

        let $searchResults = $('.js-volunteer-search-results');
        if ($searchResults.data('term') === term) return;

        $searchResults.html('');
        $searchResults.data('term', term);
        this.showSearchSpinner();

        // Отменяем предыдущий незавершенный запрос
        if (this._searchVolunteerXhr
            && this._searchVolunteerXhr.readyState
            && this._searchVolunteerXhr.readyState !== 4) {
            this._searchVolunteerXhr.abort();
        }

        this._searchVolunteerXhr = $.ajax({
            url: this.$chatToggleButton.data('search-url'),
            data: {'term': term},
            success: this.searchVolunteerResponseHandler.bind(this)
        });
    }

    searchVolunteerResponseHandler(response) {
        this.hideSearchSpinner();
        $('.js-volunteer-search-results').html(response);
    }

    expandChatWidget() {
        let self = this;
        if (!self.$chat.hasClass('show')) {
            window.setTimeout(function () {
                self.$chatToggleButton.click();
            }, 100);
        }
    }

    startChatClickHandler(element, e) {
        let $element = $(element);

        // Если кнопка в модальном окне - закрываем его
        $element.closest('.modal').modal('hide');

        this.showChannel($element.data('name'), $element.data('channel-url'))

        if (e instanceof jQuery.Event) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    showChannel(name, url) {
        let self = this;
        let $channelContent = $('.js-channel-content');
        let chatUserId = self.$chatToggleButton.data('chat-user-id');
        let chatAuthToken = self.$chatToggleButton.data('chat-auth-token');
        let baseHost = self.$chatToggleButton.data('base-host');
        let expires = new Date();
        let $channelName = $('.js-channel-name');

        this.activateTab('.tab-channel');

        // Чистим контент и включаем спиннер
        $channelContent.html('');
        $channelName.html($channelName.data('loading-message'));
        this.showChannelSpinner();

        expires.setMonth(expires.getMonth() + 1);

        document.cookie = "MMUSERID=" + chatUserId + ";expires=" + expires.toUTCString()
            + ";domain=." + baseHost + ";path=/";
        document.cookie = "MMAUTHTOKEN=" + chatAuthToken + ";expires=" + expires.toUTCString()
            + ";domain=." + baseHost + ";path=/";

        // Показываем окно чата, если свернуто
        self.expandChatWidget();

        // Загружаем канал
        $channelContent.load(url, function () {
            self.hideChannelSpinner();
            $('.js-channel-name').html(name);

            let $nativeChannel = $channelContent.find('.native-channel');
            if ($nativeChannel.length !== 0) {
                // Для нативного канала инициируем скролл и перематываем вниз
                let sb = new SimpleBar($nativeChannel[0], {
                    autoHide: false
                });
                sb.getScrollElement().scrollTop = 1000000;
                $nativeChannel.data('simplebar', sb);

                // Вешаем обработчик просмотра постов
                $('.post').each(function () {
                    const postId = $(this).data('post-id');
                    const collectUri = $(this).data('collect-uri');
                    const interval = setInterval(() => {
                        if (self.isVisible(this, $('.js-channel-content').get(0))) {
                            $.get(collectUri);
                            delete self.postIntervals[postId];
                            clearInterval(interval);
                        }
                    }, 2000);

                    self.postIntervals[postId] = interval;
                });
            }

            let $firstUnreadPost = $('#first-not-viewed-post');
            if ($firstUnreadPost.length !== 0) {
                $firstUnreadPost.attr("tabindex", -1).focus();
            }
        });
    }

    loadChannels() {
        let $channels = $('.js-channels');
        $channels.load(this.$chatToggleButton.data('channels-url'));
        $('.js-channels-content').data('simplebar').getScrollElement().scrollTop = 0;

        // Удаляем все ранее установленные обработчики постов
        Object.keys(this.postIntervals).forEach(key => delete this.postIntervals[key]);
    }

    initScrollbar() {
        $(".js-chat-scrollable").each(function (i, element) {
            let sb = new SimpleBar(element, {
                autoHide: false
            });
            $(element).data('simplebar', sb);
        });
    }

    initWebSocket() {
        let host = this.$chatToggleButton.data('base-host'),
            token = this.$chatToggleButton.data('chat-auth-token'),
            socket = new WebSocketInterface(`wss://chat.${host}/api/v4/websocket`, {
                query: {
                    token: token,
                }
            });

        socket.on('posted', () => {
            if (!this.isChatOpen) {
                this.$chatToggleButton.addClass('unread');
            }
        });
    }

    updateUnreadStatus() {
        let self = this;
        $.get(this.$chatToggleButton.data('unread-url'), function (response) {
            self.$chatToggleButton.toggleClass('unread', response && 0 < response.length);
        });
    }

    isVisible(ele, container) {
        const {bottom, height, top} = ele.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        return top <= containerRect.top
            ? (containerRect.top - top <= height)
            : (bottom - containerRect.bottom <= height);
    };
}

export default Chat;